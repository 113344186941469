import React from "react"
import Layout from "src/components/Layout"

export default () => {
  return (
    <Layout>
      <h1>404</h1>
    </Layout>
  )
}
